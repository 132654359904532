import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";

import { SearchBox } from "../../components/SearchBox";
import { CustomButton } from "../../components/CustomButton";
import { Pagination } from "../../components/Pagination";
import { DataTable } from "../../components/DataTable";
import { APP_ROUTES, getParamsAttachedRoute } from "../../helpers/routeHelpers";
import { GetAds, UpdateAdvertisementById } from "../../api/api.service";
import { MODULE_KEYS, getKey } from "../../api/api.keys";
import { useRequestProcessor } from "../../hooks/useRequestProcessors";
import { monthYear } from "../../utils/dateUtils";
import { useLocalPaginatedRequest } from "../../hooks/useLocalPaginatedRequest";
import { PageTitles, SortOrder, AdStatus } from "../../constants";
import { MODAL_CONTENT_CONFIG } from "./ModalContentConfig";
import viewIcon from "../../assets/images/app/common/listing-view-image.svg";
import CreateAd from "./CreateAd";
import { SwitchInput } from "../../components/Input";
import { ERROR, SUCCESS, showToast } from "../../utils/toastUtils";
import { ConfirmationModal, InformationModal } from "../../components/CustomModal";
import { getStatus, getStatusRunningOrCanceled, findStatusChecked, handleAdModal } from "./utils";
import { truncateString } from "../../utils/commonUtils";

const COLUMNS = {
    AD_NO: "Ad no.",
    ADVERTISEMENT_NAME: "Advertisement Name",
    START_DATE: "Created Date/Time",
    END_DATE: "End Date/Time",
    ADVERTISERS_NAME: "Advertiser's Name",
    STATUS: "Status",
    ACTION: "Actions"
};

const SORTABLE_COLUMNS = {
    [COLUMNS.ACTION]: "",
    [COLUMNS.ADVERTISEMENT_NAME]: "Title",
    [COLUMNS.ADVERTISERS_NAME]: "AdvertiserName",
    [COLUMNS.AD_NO]: "Id",
    [COLUMNS.START_DATE]: "StartAt",
    [COLUMNS.END_DATE]: ""
};

const ACTIVE_AD_LIMIT = 4;
const createNewLimitMessage = "Five ads are already running. Inactive an ad to create a new one.";
const recordUpdateSuccessMessage = "Status has been updated successfully";
const AdManagement = () => {
    const history = useHistory();

    const [showCreateAdModal, setShowCreateAdModal] = useState(false);
    const [currentSortColumn, setCurrentSortColumn] = useState(null);
    const [currentSortOrder, setCurrentSortOrder] = useState(null);
    const [activeAdRecords, setActiveAdRecords] = useState(0);
    const [modalConfig, setModalConfig] = useState(null);
    const [isCreateAddInfoModalActive, setIsCreateAddInfoModalActive] = useState(false);
    const [isAdStatusUpdating, setIsAdStatusUpdating] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({ status: "", id: "" });

    const {
        isFetching,
        page,
        handlePageClick,
        total,
        data,
        searchText,
        limit,
        handleSearchChange,
        handleSortingChange
    } = useLocalPaginatedRequest({
        key: getKey([MODULE_KEYS.AD_MANAGEMENT_LISTING]),
        requestFn: GetAds,
        params: {
            ...(currentSortColumn &&
                currentSortOrder && { Column: currentSortColumn, Direction: currentSortOrder })
        }
    });

    let { useFetch, useMutate } = useRequestProcessor();
    let { mutate } = useMutate("", getKey(), (data) =>
        UpdateAdvertisementById({ id: selectedRecord.id, bodyData: data })
    );

    // fetching active records count to enable/disable create ad button
    let { isLoading, isError, refetch } = useFetch(
        "",
        async ({ signal }) => await GetAds({ params: { OnlyActive: true }, signal }),
        {
            onSuccess: (res) => {
                setActiveAdRecords(res?.Count);
            }
        }
    );

    const handleSortClick = (item) => {
        setCurrentSortColumn(item.sortColumn);
        setCurrentSortOrder((prev) =>
            prev === SortOrder.ASC || !prev ? SortOrder.DESC : SortOrder.ASC
        );
    };

    const handleOnCancel = () => handleAdModal(setShowCreateAdModal, showCreateAdModal);
    const handleCreateAd = () => {
        if (activeAdRecords > ACTIVE_AD_LIMIT) {
            setIsCreateAddInfoModalActive(true);
            return;
        }
        handleAdModal(setShowCreateAdModal, showCreateAdModal);
    };
    const handleOnCreate = () => handleAdModal(setShowCreateAdModal, showCreateAdModal);

    const handleActionInit = (id, status) => {
        setSelectedRecord({ id, status });
        let statusToSet = getStatus(status);
        setModalConfig(MODAL_CONTENT_CONFIG[statusToSet]);
    };

    const handleActionCancellation = () => {
        setModalConfig(null);
    };

    // handling advertisement status update

    const handleAdvertisementStatusUpdate = () => {
        if (activeAdRecords > ACTIVE_AD_LIMIT && selectedRecord.status !== AdStatus.RUNNING) {
            showToast(ERROR, createNewLimitMessage);
            setModalConfig(null);
            return;
        }
        setIsAdStatusUpdating(true);
        let statusToSet = getStatus(selectedRecord.status);
        const bodyData = { Status: statusToSet };
        // Api call here
        mutate(bodyData, {
            onSuccess: async (res) => {
                setIsAdStatusUpdating(false);
                showToast(SUCCESS, recordUpdateSuccessMessage);
                data.forEach((el) => {
                    if (el?.Id == selectedRecord.id) {
                        el.Status = getStatusRunningOrCanceled(statusToSet);
                    }
                });
                refetch();
                setModalConfig(null);
            },
            onError: (err) => {
                // showToast(ERROR, err?.Message)
                setIsAdStatusUpdating(false);
            }
        });
    };

    return (
        <div className="page-content">
            <Helmet>
                <title>{PageTitles.AD_MANAGEMENT}</title>
            </Helmet>
            <Container fluid>
                <Row>
                    <Col sm="12" md="3" lg="3">
                        <SearchBox
                            className="me-3 w-50"
                            value={searchText}
                            onChange={handleSearchChange}
                        />
                    </Col>
                    <Col sm="12" md="6" lg="6" className="my-2"></Col>
                    {!isLoading && !isError && (
                        <Col sm="12" md="3" lg="3" align="right">
                            <CustomButton
                                title="Add an Ad"
                                color="primary"
                                className="custom-btn-lg w-100 max-width-percent-modified"
                                onClick={handleCreateAd}
                                // disabled={activeAdRecords > ACTIVE_AD_LIMIT}
                            />
                        </Col>
                    )}
                </Row>

                <DataTable
                    tableContainerClass="mt-3"
                    loading={isFetching}
                    data={data}
                    currentSortOrder={currentSortOrder}
                    currentSortColumn={currentSortColumn}
                    onSortClick={handleSortClick}
                    config={[
                        {
                            sortColumn: SORTABLE_COLUMNS[COLUMNS.AD_NO],
                            isSortable: true,
                            title: COLUMNS.AD_NO,
                            render: (data) => data.Id
                        },
                        {
                            sortColumn: SORTABLE_COLUMNS[COLUMNS.ADVERTISEMENT_NAME],
                            isSortable: true,
                            title: COLUMNS.ADVERTISEMENT_NAME,
                            render: (data) => truncateString(data.Title, 30)
                        },
                        {
                            sortColumn: SORTABLE_COLUMNS[COLUMNS.START_DATE],
                            isSortable: true,
                            title: COLUMNS.START_DATE,
                            className: "colomn-width-limit",
                            render: (data) => monthYear("DD/MM/YY hh:mm:ss", data.StartAt) || "-"
                        },
                        {
                            sortColumn: SORTABLE_COLUMNS[COLUMNS.ADVERTISERS_NAME],
                            isSortable: true,
                            title: COLUMNS.ADVERTISERS_NAME,
                            render: (data) => truncateString(data.AdvertiserName, 30) || "-"
                        },
                        {
                            title: COLUMNS.ACTION,
                            render: (data) => {
                                return (
                                    <div className="d-flex ">
                                        <img
                                            src={viewIcon}
                                            role="button"
                                            alt={"details icon"}
                                            onClick={() =>
                                                history.push(
                                                    getParamsAttachedRoute(APP_ROUTES.AD_DETAILS, {
                                                        adId: data?.Id
                                                    })
                                                )
                                            }
                                        />
                                        <div className="ad-management-details-option-container ms-2">
                                            <SwitchInput
                                                size="sm"
                                                isChecked={findStatusChecked(data?.Status)}
                                                onClick={() =>
                                                    handleActionInit(data?.Id, data?.Status)
                                                }
                                            />
                                        </div>
                                    </div>
                                );
                            }
                        }
                    ]}
                />
                <Pagination
                    rowLimit={limit}
                    onPageClick={handlePageClick}
                    currentPage={page}
                    totalData={total}
                    handleChange={(e) => handleSortingChange(e.target.value)}
                />
                <CreateAd
                    onCancel={handleOnCancel}
                    onCreate={handleOnCreate}
                    open={showCreateAdModal}
                />
            </Container>
            <ConfirmationModal
                size="md"
                hasCustomConfig
                isOpen={modalConfig}
                customImageSrc={modalConfig?.imageSrc}
                customTitle={modalConfig?.modalTitle}
                customYesBtnText={modalConfig?.yesButtonText}
                customNoBtnText={modalConfig?.noButtonText}
                onYes={handleAdvertisementStatusUpdate}
                onNo={handleActionCancellation}
                yesLoading={isAdStatusUpdating}
            />
            <InformationModal
                size="md"
                isOpen={isCreateAddInfoModalActive}
                Title={createNewLimitMessage}
                confirmButtonText="Ok"
                onConfirmClick={() => setIsCreateAddInfoModalActive(false)}
            />
        </div>
    );
};

export default AdManagement;

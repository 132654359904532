import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { useFormik } from "formik";

import { CustomModal } from "../../components/CustomModal";
import { TextInput } from "../../components/Input";
import { LABLES, NAMES, PLACEHOLDERS } from "./AdsFieldsNames";
import { CustomButton } from "../../components/CustomButton";
import { ImageUploader } from "../../components/ImageUploader";
import {
    initialValues,
    adManagementFormValidationSchema,
    validationMessagesImage
} from "./FormValidations";
import { useRequestProcessor } from "../../hooks/useRequestProcessors";
import { MODULE_KEYS, getKey } from "../../api/api.keys";
import { CreateAdvertisement } from "../../api/api.service";
import { ERROR, SUCCESS, showToast } from "../../utils/toastUtils";
import { uploadMediaToServer } from "../../utils/mediaUtils";
import FormikErrorText from "../../components/FormikErrorText/FormikErrorText";

let { ADVERTISEMENT_LINK, ADVERTISEMENT_NAME, ADVERTISERS_NAME, ADVERTISERS_EMAIL, ADD_IMGAE } =
    LABLES;

const CreateAd = ({ open = false, onCreate = () => {}, onCancel = () => {} }) => {
    const [adImageContent, setAdImageContent] = useState("");
    const [imageValidationError, setImageValidationError] = useState(false);
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);

    useEffect(() => {
        formik.resetForm();
    }, [open]);

    useEffect(() => {
        if (adImageContent) setImageValidationError(false);
    }, [adImageContent]);

    let { useMutate } = useRequestProcessor();
    let { mutate } = useMutate(
        MODULE_KEYS.AD_MANAGEMENT_LISTING,
        getKey([MODULE_KEYS.CREATE_AD]),
        (data) => CreateAdvertisement({ bodyData: data })
    );

    const formik = useFormik({
        initialValues: initialValues.adManagementFields,
        enableReinitialize: true,
        validationSchema: adManagementFormValidationSchema,
        onSubmit: (values) => {
            handleFormSubmit(values);
        }
    });

    const handleFormSubmit = async (values) => {
        setIsFormSubmitting(true);
        if (adImageContent === "") {
            if (!adImageContent) setImageValidationError(true);
            setIsFormSubmitting(false);
            return;
        }
        let imageId = await uploadMediaToServer(adImageContent, setIsFormSubmitting);
        const bodyData = {
            Title: values[NAMES[ADVERTISEMENT_NAME]],
            AdvertiserName: values[NAMES[ADVERTISERS_NAME]],
            AdvertiserEmail: values[NAMES[ADVERTISERS_EMAIL]],
            Url: values[NAMES[ADVERTISEMENT_LINK]],
            MediaId: imageId
        };
        mutate(bodyData, {
            onSuccess: (res) => createAdSuccessHandler(),
            onError: (err) => createAdErrorHandler(err)
        });
    };

    const createAdSuccessHandler = () => {
        setIsFormSubmitting(false);
        showToast(SUCCESS, "Advertisement has been created successfully");
        onCreate();
    };
    const createAdErrorHandler = (error) => {
        setIsFormSubmitting(false);
        // showToast(ERROR, error?.Message)
    };
    return (
        <div>
            <CustomModal isOpen={open} contentClassName="p-4 pt-0" size="lg">
                <form onSubmit={formik.handleSubmit}>
                    <Row className="my-4">
                        <Col sm="12" md="6" lg="6" xl="6">
                            <TextInput
                                inputClasses="ad-input"
                                label={ADVERTISEMENT_LINK}
                                name={NAMES[ADVERTISEMENT_LINK]}
                                placeholder={PLACEHOLDERS[ADVERTISEMENT_LINK]}
                                value={formik.values[NAMES[ADVERTISEMENT_LINK]]}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <FormikErrorText
                                formikInstance={formik}
                                fieldName={[NAMES[ADVERTISEMENT_LINK]]}
                            />
                        </Col>
                        <Col sm="12" md="6" lg="6" xl="6">
                            <TextInput
                                inputClasses="ad-input"
                                label={ADVERTISEMENT_NAME}
                                name={NAMES[ADVERTISEMENT_NAME]}
                                placeholder={PLACEHOLDERS[ADVERTISEMENT_NAME]}
                                value={formik.values[NAMES[ADVERTISEMENT_NAME]]}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <FormikErrorText
                                formikInstance={formik}
                                fieldName={[NAMES[ADVERTISEMENT_NAME]]}
                            />
                        </Col>
                    </Row>
                    <Row className="my-4">
                        <Col sm="12" md="6" lg="6" xl="6">
                            <TextInput
                                inputClasses="ad-input"
                                label={ADVERTISERS_NAME}
                                name={NAMES[ADVERTISERS_NAME]}
                                placeholder={PLACEHOLDERS[ADVERTISERS_NAME]}
                                value={formik.values[NAMES[ADVERTISERS_NAME]]}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <FormikErrorText
                                formikInstance={formik}
                                fieldName={[NAMES[ADVERTISERS_NAME]]}
                            />
                        </Col>
                        <Col sm="12" md="6" lg="6" xl="6">
                            <TextInput
                                inputClasses="ad-input"
                                label={ADVERTISERS_EMAIL}
                                name={NAMES[ADVERTISERS_EMAIL]}
                                placeholder={PLACEHOLDERS[ADVERTISERS_EMAIL]}
                                value={formik.values[NAMES[ADVERTISERS_EMAIL]]}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <FormikErrorText
                                formikInstance={formik}
                                fieldName={[NAMES[ADVERTISERS_EMAIL]]}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="12" md="12" lg="12" className="my-4">
                            <ImageUploader
                                label={ADD_IMGAE}
                                setContentImage={setAdImageContent}
                                imageValidtion={
                                    imageValidationError && validationMessagesImage?.requiredError
                                }
                            />
                        </Col>
                    </Row>

                    <div className="ad-modal-resopnsiveness d-flex justify-content-lg-start justify-content-md-start justify-content-sm-center flex-wrap pt-2 ">
                        <CustomButton
                            title="Create"
                            color="primary"
                            className="custom-btn-lg"
                            type={"submit"}
                            loading={isFormSubmitting}
                        />
                        <CustomButton
                            title="Cancel"
                            outline
                            color="primary"
                            className="custom-btn-lg-secondary mx-md-2 mx-lg-2"
                            onClick={onCancel}
                            type={"button"}
                            disabled={isFormSubmitting}
                        />
                    </div>
                </form>
            </CustomModal>
        </div>
    );
};

export default CreateAd;

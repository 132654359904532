export let LABLES = {
    ARTICLE_NAME: "Article Name",
    WEBSITE_NAME: "Website Name",
    WEBSITE_LINK: "Website Link",
    TEASER_TEXT: "Teaser Text",
    TOTAL_CLICKS: "Total Clicks",
    SUBTITLES: "Subtitle",
    SKILL_LEVEL: "Skill Level"
};

export let NAMES = {
    [LABLES.ARTICLE_NAME]: "articleName",
    [LABLES.TEASER_TEXT]: "teaserText",
    [LABLES.TOTAL_CLICKS]: "ClickCount",
    [LABLES.WEBSITE_LINK]: "websiteLinks",
    [LABLES.WEBSITE_NAME]: "websiteName",
    [LABLES.SUBTITLES]: "subtitles",
    [LABLES.SKILL_LEVEL]: "skillLevel"
};

export let PLACEHOLDERS = {
    [LABLES.ARTICLE_NAME]: "Beginner’s Guide To Disc Golf",
    [LABLES.TEASER_TEXT]: "Teaser Text",
    [LABLES.TOTAL_CLICKS]: "",
    [LABLES.WEBSITE_LINK]: "discgolfguide.com",
    [LABLES.WEBSITE_NAME]: "Discgolf",
    [LABLES.SUBTITLES]: "Cold Laser Therapy for Chronic Pain: Should You Try It",
    [LABLES.SKILL_LEVEL]: "Skill Level"
};

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { withRouter, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { Row, Col } from "reactstrap";

import AuthWrapper from "./AuthWrapper";
import { CodeInput } from "../../components/CodeInput";
import { CustomButton } from "../../components/CustomButton";
import { APP_ROUTES, getParamsAttachedRoute } from "../../helpers/routeHelpers";
import { ForgotPasswordVerification, ForgotPassword } from "../../api/api.service";
import { PageTitles } from "../../constants";
import Headings from "./Headings";
import { useRef } from "react";
import { SUCCESS, showToast } from "../../utils/toastUtils";

const OTP_LENGTH = 4;

const ForgetVerifyCode = ({}) => {
    const history = useHistory();
    const location = useLocation();
    const { tokenId } = useParams();
    let isAuthenticated = useSelector((state) => state?.userAuth?.isAuthenticated);

    let otpRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [OTP, setOTP] = useState("");
    const [email, setEmail] = useState("");
    const [token, setToken] = useState("");
    const [codeAndToken, setCodeAndToken] = useState({
        tokenUUId: ""
    });
    const [isResendCode, setIsResendCode] = useState(false);
    const [showOtpModal, setShowOtpModal] = useState(false);

    useEffect(() => {
        if (!tokenId) {
            return;
        }

        setToken(tokenId);
        setEmail(location?.state?.email || "");
    }, [tokenId]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);

            let body = {
                Code: OTP,
                TokenUuid: token
            };
            let res = await ForgotPasswordVerification(body);
            let { TokenUuid } = res;
            setCodeAndToken({
                tokenUUId: TokenUuid
            });
            history.push(
                getParamsAttachedRoute(APP_ROUTES.RESET_PASSWORD, { tokenUUId: TokenUuid })
            );
            setShowOtpModal(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleResendCode = async () => {
        try {
            setIsResendCode(true);
            let res = await ForgotPassword({ Email: email });
            otpRef.current?.clear();
            showToast(SUCCESS, "An OTP has been sent to your email address !");
            history.replace({
                pathname: getParamsAttachedRoute(APP_ROUTES.FORGET_VERIFY_CODE, {
                    tokenId: res?.TokenUuid
                }),
                state: { email }
            });
        } finally {
            setIsResendCode(false);
            setOTP("");
        }
    };

    if (!location?.state?.email) {
        <Redirect to={APP_ROUTES.DASHBOARD} />;
    }

    if (isAuthenticated) {
        return <Redirect to={APP_ROUTES.DASHBOARD} />;
    }

    return (
        <>
            <Helmet>
                <title>{PageTitles.OTP_VERIFICATION}</title>
            </Helmet>
            <AuthWrapper>
                <div className="bv-forget-wrap">
                    <Headings title="OTP VERIFICATION" subText="One Time Password" />

                    <form className="mt-5" onSubmit={handleSubmit}>
                        <h6 className="font-clr-theme-secondary mb-4 font-size-12 text-left p-0">
                            Please enter the one time password (OTP) sent to your email
                        </h6>
                        <Row>
                            <Col md={12}>
                                <CodeInput
                                    className="my-4 text-center"
                                    onChange={(e) => setOTP(e)}
                                    ref={otpRef}
                                />
                            </Col>
                        </Row>
                        <CustomButton
                            disabled={OTP.length != OTP_LENGTH}
                            loading={isLoading}
                            onClick={handleSubmit}
                            color="primary"
                            type={"submit"}
                            title="Verify"
                            className="w-100 mt-4"
                        />
                    </form>
                    <div className="d-flex mt-4 align-items-center justify-content-center responsiveness">
                        <p className="font-clr-theme-secondary fw-bold"> Didn’t Receive Yet ? </p>
                        <p
                            className="clr-theme-primary ms-1 fw-bold cursor-pointer"
                            onClick={handleResendCode}
                        >
                            {isResendCode ? "Sending..." : "Resend Now"}
                        </p>
                    </div>
                    <div>
                        <Link
                            className="d-flex align-items-center justify-content-center my-4"
                            to={APP_ROUTES.LOGIN}
                        >
                            <p className="clr-theme-primary fw-bold"> Back to Login </p>
                        </Link>
                    </div>
                </div>
            </AuthWrapper>
        </>
    );
};

export default withRouter(ForgetVerifyCode);

export const customStyles = {
  skillLevel: {
      container: (provided) => ({
          display: "inline-block",
          position: "relative",
          width: "100%",
          height: "34px",
          borderRadius: "4px",
          color: 'red' ,
          ...provided
      }),
      control: (provided, state) => ({
          ...provided,
          height: "34px",
          minHeight: "36px",
          borderColor: "#ebebeb",
          boxShadow: '0px 1px 4px #8b8a8a29',
          // boxShadow: state.isFocused ? 0 : 0,
          "&:hover": {
              borderWidth: "2px",
              borderColor: "#2795AE"
          }
      }),
      option: (provided, state) => ({
          ...provided,
          // fontWeight: 500,
          color: state.isSelected ? "#fff" : "#000000",
          fontFamily: "Poppins",
          textAlign: "center",
          fontSize: '12px',
          fontWeight: '400'
      }),
     
  }
};
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";

import { SearchBox } from "../../components/SearchBox";
import { CustomButton } from "../../components/CustomButton";
import { Pagination } from "../../components/Pagination";
import { DataTable } from "../../components/DataTable";
import AddContent from "./AddContent";
import { APP_ROUTES, getParamsAttachedRoute } from "../../helpers/routeHelpers";
import { DeleteArticleById, GetArticles } from "../../api/api.service";
import { MODULE_KEYS, getKey } from "../../api/api.keys";
import { useLocalPaginatedRequest } from "../../hooks/useLocalPaginatedRequest";
import { PageTitles, SortOrder } from "../../constants";
import viewIcon from "../../assets/images/app/common/listing-view-image.svg";
import editIcon from "../../assets/images/app/common/listing-edit-image.svg";
import deleteIcon from "../../assets/images/app/common/listing-delete-image.svg";
import { ConfirmationModal } from "../../components/CustomModal";
import { monthYear } from "../../utils/dateUtils";
import { ERROR, SUCCESS, showToast } from "../../utils/toastUtils";
import { useRequestProcessor } from "../../hooks/useRequestProcessors";
import { handleAdModal } from "../AdManagement/utils";
import { truncateString } from "../../utils/commonUtils";

const COLUMNS = {
    ARTICLE_NAME: "Article Name",
    WEBSITE_NAME: "Website Name",
    WEBSITE_LINK: "Website Link",
    POST_DATE: "Post Date",
    TOTAL_CLICKS: "Total Clicks",
    ACTION: "Actions"
};

const SORTABLE_COLUMNS = {
    [COLUMNS.ARTICLE_NAME]: "Title",
    [COLUMNS.WEBSITE_NAME]: "WebsiteName",
    [COLUMNS.WEBSITE_LINK]: "Url",
    [COLUMNS.POST_DATE]: "UpdatedAt",
    [COLUMNS.TOTAL_CLICKS]: "ClickCount"
};

const ContentManagement = () => {
    const history = useHistory();

    const [showAddContentModal, setShowAddContentModal] = useState(false);
    const [currentSortColumn, setCurrentSortColumn] = useState(null);
    const [currentSortOrder, setCurrentSortOrder] = useState(null);
    const [contentId, setContentId] = useState(undefined);
    const [isDeletingArticle, setIsDeletingArticle] = useState(false);
    const [isDeleteUser, setIsDeleteUser] = useState(false);
    // article delete handling
    let { useMutate } = useRequestProcessor();

    // handling article delete API
    let { mutate: deleteArticleMutate } = useMutate(
        MODULE_KEYS.CONTENT_MANAGEMENT_LISTING,
        getKey([MODULE_KEYS.DELETE_CONTENT]),
        (data) => DeleteArticleById(contentId, data)
    );
    const {
        isFetching,
        page,
        total,
        data,
        limit,
        searchText,
        handlePageClick,
        handleSearchChange,
        handleSortingChange
    } = useLocalPaginatedRequest({
        key: getKey([MODULE_KEYS.CONTENT_MANAGEMENT_LISTING]),
        requestFn: GetArticles,
        params: {
            ...(currentSortColumn &&
                currentSortOrder && { Column: currentSortColumn, Direction: currentSortOrder })
        }
    });

    const handleSortClick = (item) => {
        setCurrentSortColumn(item.sortColumn);
        setCurrentSortOrder((prev) =>
            prev === SortOrder.ASC || !prev ? SortOrder.DESC : SortOrder.ASC
        );
    };

    const handleOnCancel = () => handleAdModal(setShowAddContentModal, showAddContentModal);
    const handleAddContent = () => handleAdModal(setShowAddContentModal, showAddContentModal);
    const handleOnCreate = () => handleAdModal(setShowAddContentModal, showAddContentModal);

    const deleteClickHandler = (articleId) => {
        setContentId(articleId);
        setIsDeleteUser(true);
    };

    const handleDeleteArticle = () => {
        setIsDeletingArticle(true);
        // Api call here
        deleteArticleMutate(
            {},
            {
                onSuccess: async (res) => {
                    setIsDeletingArticle(false);
                    showToast(SUCCESS, "Article has been deleted successfully");
                    setIsDeleteUser(false);
                },
                onError: (err) => {
                    // showToast(ERROR, err?.Message)
                    setIsDeletingArticle(false);
                }
            }
        );
    };

    return (
        <div className="page-content">
            <Helmet>
                <title>{PageTitles.CONTENT_MANAGEMENT}</title>
            </Helmet>
            <Container fluid>
                <Row>
                    <Col sm="12" md="3" lg="3">
                        <SearchBox
                            className="me-3 w-50"
                            value={searchText}
                            onChange={handleSearchChange}
                        />
                    </Col>
                    <Col sm="12" md="6" lg="6" className="my-2"></Col>
                    <Col sm="12" md="3" lg="3" align="right">
                        <CustomButton
                            title="Add Content"
                            color="primary"
                            className="custom-btn-lg w-100 max-width-percent-modified"
                            onClick={handleAddContent}
                        />
                    </Col>
                </Row>

                <DataTable
                    tableContainerClass="mt-3"
                    loading={isFetching}
                    data={data}
                    currentSortOrder={currentSortOrder}
                    currentSortColumn={currentSortColumn}
                    onSortClick={handleSortClick}
                    config={[
                        {
                            sortColumn: SORTABLE_COLUMNS[COLUMNS.ARTICLE_NAME],
                            isSortable: true,
                            title: COLUMNS.ARTICLE_NAME,
                            render: (data) => truncateString(data.Title, 30)
                        },

                        {
                            sortColumn: SORTABLE_COLUMNS[COLUMNS.WEBSITE_NAME],
                            isSortable: true,
                            title: COLUMNS.WEBSITE_NAME,
                            render: (data) => truncateString(data.WebsiteName, 30) || "-"
                        },

                        {
                            sortColumn: SORTABLE_COLUMNS[COLUMNS.WEBSITE_LINK],
                            isSortable: true,
                            title: COLUMNS.WEBSITE_LINK,
                            render: (data) =>
                                (
                                    <a href={data.Url} target="_blank">
                                        {truncateString(data.Url, 30)}
                                    </a>
                                ) || "-"
                        },
                        {
                            sortColumn: SORTABLE_COLUMNS[COLUMNS.POST_DATE],
                            isSortable: true,
                            title: COLUMNS.POST_DATE,
                            render: (data) => monthYear("DD/MM/YY ", data.CreatedAt) || "-"
                        },
                        {
                            sortColumn: SORTABLE_COLUMNS[COLUMNS.TOTAL_CLICKS],
                            isSortable: true,
                            title: COLUMNS.TOTAL_CLICKS,
                            render: (data) => data.ClickCount || "-"
                        },

                        {
                            title: COLUMNS.ACTION,
                            render: (data) => {
                                return (
                                    <div className="d-flex ">
                                        <img
                                            className="me-3"
                                            src={viewIcon}
                                            alt={"details icon"}
                                            role="button"
                                            onClick={() =>
                                                history.push(
                                                    getParamsAttachedRoute(
                                                        APP_ROUTES.CONTENT_MANAGEMENT_DETAILS,
                                                        {
                                                            contentId: data?.Id
                                                        }
                                                    )
                                                )
                                            }
                                        />
                                        <img
                                            className="me-3"
                                            src={editIcon}
                                            role="button"
                                            alt={"edit icon"}
                                            onClick={() =>
                                                history.push(
                                                    getParamsAttachedRoute(
                                                        APP_ROUTES.CONTENT_MANAGEMENT_UPDATE,
                                                        {
                                                            contentId: data?.Id
                                                        }
                                                    )
                                                )
                                            }
                                        />
                                        <img
                                            src={deleteIcon}
                                            role="button"
                                            alt={"delete icon"}
                                            onClick={() => deleteClickHandler(data?.Id)}
                                        />
                                    </div>
                                );
                            }
                        }
                    ]}
                />
                <Pagination
                    rowLimit={limit}
                    onPageClick={handlePageClick}
                    currentPage={page}
                    totalData={total}
                    handleChange={(e) => handleSortingChange(e.target.value)}
                />
                <AddContent
                    onCancel={handleOnCancel}
                    onCreate={handleOnCreate}
                    open={showAddContentModal}
                />
                <ConfirmationModal
                    isOpen={isDeleteUser}
                    onYes={handleDeleteArticle}
                    yesLoading={isDeletingArticle}
                    size="md"
                    onNo={() => setIsDeleteUser(false)}
                />
            </Container>
        </div>
    );
};

export default ContentManagement;

import React from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody } from "reactstrap";

import { graphConfiguration } from "./GraphConfig";
import { GraphFilter } from "./GraphFilter";

const Graph = ({ graphType = "line", height = 320, data = [], getFilterData = () => {} }) => {
    return (
        <Card className="graph-border graph-card">
            <CardBody>
                <GraphFilter isFilterable getFilterData={getFilterData} />
                <div id="line-chart" className="apex-charts" dir="ltr">
                    <ReactApexChart
                        series={data}
                        options={graphConfiguration}
                        type={graphType}
                        height={height}
                    />
                </div>
            </CardBody>
        </Card>
    );
};

export default Graph;

// To Learn: https://apexcharts.com/docs/react-charts/

import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container } from "reactstrap";
import { useHistory } from "react-router-dom";

import { Pagination } from "../../components/Pagination";
import { DataTable } from "../../components/DataTable";
import { APP_ROUTES, getParamsAttachedRoute } from "../../helpers/routeHelpers";
import { GetUsers } from "../../api/api.service";
import { useLocalPaginatedRequest } from "../../hooks/useLocalPaginatedRequest";
import { PageHeadings, PageTitles, SkillLevel, SortOrder } from "../../constants";
import { UserAvatar } from "../../components/Avatar";
import { MODULE_KEYS, getKey } from "../../api/api.keys";
import viewIcon from "../../assets/images/app/common/listing-view-image.svg";
import ListingHeader from "../../components/DataTable/ListingHeader";
import { handleFilterChange } from "../../utils/tableUtils";
import APP_CONFIG from "../../APP_CONFIG";
import { getMediaPath } from "../../utils/mediaUtils";
import { truncateString } from "../../utils/commonUtils";

const COLUMNS = {
    PROFILE_PICTURE: "Profile Picture",
    FULL_NAME: "Full Name",
    EMAIL: "Email Address",
    ADDRESS: "Address",
    SKILL_LEVEL: "Skill Level",
    ACTION: "Actions"
};

const SORTABLE_COLUMNS = {
    [COLUMNS.FULL_NAME]: "FullName",
    [COLUMNS.EMAIL]: "Email",
    // [COLUMNS.ADDRESS]: "Address",
    [COLUMNS.SKILL_LEVEL]: "SkillLevel"
};

const UserManagement = () => {
    const history = useHistory();

    const [currentSortColumn, setCurrentSortColumn] = useState(null);
    const [currentSortOrder, setCurrentSortOrder] = useState(null);

    const [filters, setFilters] = useState({
        [SORTABLE_COLUMNS[COLUMNS.SKILL_LEVEL]]: null
    });

    const {
        isFetching,
        page,
        total,
        data,
        searchText,
        refetch,
        limit,
        handlePageClick,
        handleSearchChange,
        handleSortingChange
    } = useLocalPaginatedRequest({
        key: getKey([MODULE_KEYS.USER_MANAGEMENT_LISTING]),
        requestFn: GetUsers,
        params: {
            ...(currentSortColumn &&
                currentSortOrder && { Column: currentSortColumn, Direction: currentSortOrder }),
            ...(filters[SORTABLE_COLUMNS[COLUMNS.SKILL_LEVEL]] && {
                [SORTABLE_COLUMNS[COLUMNS.SKILL_LEVEL]]:
                    filters[SORTABLE_COLUMNS[COLUMNS.SKILL_LEVEL]]
            })
        }
    });

    const handleSortClick = (item) => {
        setCurrentSortColumn(item.sortColumn);
        setCurrentSortOrder((prev) =>
            prev === SortOrder.ASC || !prev ? SortOrder.DESC : SortOrder.ASC
        );
    };
    return (
        <div className="page-content">
            <Helmet>
                <title>{PageTitles.USER_MANAGEMENT}</title>
            </Helmet>
            <Container fluid>
                <ListingHeader
                    onSearchChange={handleSearchChange}
                    searchedText={searchText}
                    title={PageHeadings.USER_MANAGAMENT}
                />

                <DataTable
                    tableContainerClass="mt-3"
                    loading={isFetching}
                    data={data}
                    currentSortOrder={currentSortOrder}
                    currentSortColumn={currentSortColumn}
                    onSortClick={handleSortClick}
                    config={[
                        {
                            title: COLUMNS.PROFILE_PICTURE,
                            render: (data) => (
                                <UserAvatar
                                    imgSrc={
                                        getMediaPath(data.ProfileImage) ||
                                        APP_CONFIG.IMAGES.AVATAR_PLACEHOLDER
                                    }
                                    altText={data.FullName}
                                />
                            )
                        },

                        {
                            sortColumn: SORTABLE_COLUMNS[COLUMNS.FULL_NAME],
                            isSortable: true,
                            title: COLUMNS.FULL_NAME,
                            render: (data) => <div className="py-3">{truncateString(data.FullName, 30) || "-"}</div>
                        },

                        {
                            sortColumn: SORTABLE_COLUMNS[COLUMNS.EMAIL],
                            isSortable: true,
                            title: COLUMNS.EMAIL,
                            render: (data) => <div className="py-3">{data.Email || "-"}</div>
                        },
                        {
                            // sortColumn: SORTABLE_COLUMNS[COLUMNS.ADDRESS],
                            // isSortable: true,
                            title: COLUMNS.ADDRESS,
                            render: (data) => (
                                <div className="py-3">{truncateString(data?.HomeCourse?.Address, 30) || "-"}</div>
                            )
                        },
                        {
                            isFilterable: true,
                            filterConstants: SkillLevel,
                            filterValue: filters[SORTABLE_COLUMNS[COLUMNS.SKILL_LEVEL]],
                            title: COLUMNS.SKILL_LEVEL,
                            render: (data) => <div className="py-3">{data.SkillLevel || "-"}</div>,
                            onFilterSelect: (value) =>
                                handleFilterChange(
                                    SORTABLE_COLUMNS[COLUMNS.SKILL_LEVEL],
                                    value,
                                    handlePageClick,
                                    setFilters
                                )
                        },
                        {
                            title: COLUMNS.ACTION,
                            render: (data) => {
                                return (
                                    <div className="py-3">
                                        <img
                                            src={viewIcon}
                                            role="button"
                                            onClick={() =>
                                                history.push(
                                                    getParamsAttachedRoute(
                                                        APP_ROUTES.USER_MANAGEMENT_DETAILS,
                                                        {
                                                            userId: data?.Id || 1
                                                        }
                                                    )
                                                )
                                            }
                                        />
                                    </div>
                                );
                            }
                        }
                    ]}
                />

                <Pagination
                    onPageClick={handlePageClick}
                    currentPage={page}
                    totalData={total}
                    handleChange={(e) => handleSortingChange(e.target.value)}
                    rowLimit={limit}
                />
            </Container>
        </div>
    );
};

export default UserManagement;

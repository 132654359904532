export default {
    LOGIN: "Login",
    FORGET_PASSWORD: "Forget Password",
    OTP_VERIFICATION: "OTP Verification",
    LOGOUT: "Logout",
    REGISTER: "Register",
    RESET_PASSWORD: "Reset Password",
    DASHBOARD: "Dashboard",
    USER_MANAGEMENT: "User Management",
    CONTENT_MANAGEMENT: "Content Management",
    COURSE_MANAGEMENT: "Course Management",
    AD_MANAGEMENT: "Ad Management",
    ACCOUNT: "Account"
};

export let LABLES = {
    COURSE_NAME: "Course Name",
    ADDRESS: "Address",
    CITY: "City",
    STATE: "State",
    COURSE_FEE: "Course Fee",
    PARKING: "Parking",
    BASKETS: "Baskets",
    TEE_PADS: "Tee Pads",
    NOTES: "Notes",
    OPERATION_TIMINGS: "Operation Timigs",
    ACTION: "Actions",
    COURSE_PROFILE_LINK: "PDGA Course Profile Link"
};

export let NAMES = {
    [LABLES.ACTION]: "actions",
    [LABLES.ADDRESS]: "address",
    [LABLES.BASKETS]: "baskets",
    [LABLES.CITY]: "city",
    [LABLES.STATE]: "state",
    [LABLES.COURSE_FEE]: "courseFee",
    [LABLES.COURSE_NAME]: "courseName",
    [LABLES.NOTES]: "notes",
    [LABLES.OPERATION_TIMINGS]: "operationTimings",
    [LABLES.PARKING]: "parking",
    [LABLES.TEE_PADS]: "teePads",
    [LABLES.COURSE_PROFILE_LINK]: "courseProfileLink"
};

export let PLACEHOLDERS = {
    [LABLES.ACTION]: "",
    [LABLES.ADDRESS]: "1600 Neptune Dr. San Leandro, CA 94577",
    [LABLES.BASKETS]: "36 Disc Catcher Baskets",
    [LABLES.CITY]: "Houston",
    [LABLES.STATE]: "California",
    [LABLES.COURSE_FEE]: "$9.99",
    [LABLES.COURSE_NAME]: "Oyster Bay Disc Golf Course",
    [LABLES.NOTES]: "Street Parking, .25 mile walk to hole #1 ",
    [LABLES.OPERATION_TIMINGS]: "discgolfguide.com",
    [LABLES.PARKING]: "Free Parking on Neptune Dr.",
    [LABLES.TEE_PADS]: "36 Rubber Tee Pads",
    [LABLES.COURSE_PROFILE_LINK]: "discgolfguide.com"
};

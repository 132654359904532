import { injectBaseConstantMethods } from "./BaseConstants";

const SkillLevel = {
    Any: "Any",
    Beginner: "Beginner",
    Intermediate: "Intermediate",
    Advanced: "Advanced"
};

const displayTextKeys = {
    [SkillLevel.Any]: "Any",
    [SkillLevel.Advanced]: "Advanced",
    [SkillLevel.Intermediate]: "Intermediate",
    [SkillLevel.Beginner]: "Beginner"
};

const labelClass = {
    [SkillLevel.Any]: "skill-any",
    [SkillLevel.Advanced]: "skill-advance",
    [SkillLevel.Intermediate]: "skill-intermediate",
    [SkillLevel.Beginner]: "skill-beginner"
};

export default injectBaseConstantMethods(SkillLevel, displayTextKeys, labelClass);

import React from "react";
import { Row, Col } from "reactstrap";

import { useRequestProcessor } from "../../hooks/useRequestProcessors";
import { ListBox } from "../../components/ListBox";
import { GetAllRounds } from "../../api/api.service";
import { MODULE_KEYS } from "../../api/api.keys";
import { LoaderFallbackUI } from "../../components/LoaderFallbackUI";
import { hasData } from "../../utils/commonUtils";
import { convertDateTime, dateTimeFormat } from "../../utils/dateFormatUtils";

const ROUNDS_TO_SHOW = 4;

let handleRoundDataFormat = (obj = {}) => {
    let temp = [];

    temp.push(
        { key: "Course Name", value: obj?.Course?.Name },
        { key: "Round Type", value: obj?.Type },
        {
            key: "Date Time",
            value: convertDateTime({
                date: obj?.EventDateTime,
                customFormat: dateTimeFormat?.appMonthDateTimeFormat
            })
        },
        { key: "Skill Level", value: obj?.SkillLevel }
    );
    return temp;
};

const RoundsSection = () => {
    let { useFetch } = useRequestProcessor();
    let { data, isLoading, isError } = useFetch(
        [MODULE_KEYS.DASHBOARD_ROUNDS],
        async ({ signal }) => await GetAllRounds({ Limit: ROUNDS_TO_SHOW }, signal)
    );

    if (isLoading || !hasData(data) || isError) {
        return (
            <Row>
                <LoaderFallbackUI className="roundsection-loader" isLoader={isLoading} />
            </Row>
        );
    }

    return (
        <Row>
            {data?.Data?.map((item, ind) => {
                let aRoundDetails = handleRoundDataFormat(item);
                return (
                    <Col sm="12" lg="3" md="6">
                        <ListBox key={ind} title={item?.Name || "-"} data={aRoundDetails} />
                    </Col>
                );
            })}
        </Row>
    );
};

export default RoundsSection;

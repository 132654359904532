import React, { useState } from "react";
import { Row, Col } from 'reactstrap';
import moment from "moment";

import { monthYear } from "../../utils/dateUtils";
import { useRequestProcessor } from "../../hooks/useRequestProcessors";
import { Graph } from "../../components/Graph";
import { LoaderFallbackUI } from "../../components/LoaderFallbackUI";
import { MODULE_KEYS } from "../../api/api.keys";
import { GetGraphData } from "../../api/api.service";
import { getKey } from "../../api/api.keys";

const MONTH = "month";
const DATE_MONTH_FORMAT = "M";
const DATE_YEAR_FORMAT = "YYYY";
const DEFAULT_DATE_FORMAT = "MMMM, YYYY";
const TOTAL_USERS = "Total Users";
const ACTIVE_USERS = "Active Users";
const currentMonthYear = monthYear(DEFAULT_DATE_FORMAT);

const GraphSection = () => {
    const [graphData, setGraphData] = useState([]);
    const [selectedDate, setSelectedDate] = useState({ date: currentMonthYear, type: MONTH });
    // getting the month and year of selected date using moment
    const selectedDateMonth = moment().month(selectedDate?.date).format(DATE_MONTH_FORMAT);
    const selectedDateYear = moment().year(selectedDate?.date).format(DATE_YEAR_FORMAT);
    // making request body according to type selected
    const requestBodyYear = { Year: selectedDateYear };
    const requestBodyMonthYear = { Year: selectedDateYear, Month: selectedDateMonth };
    let requestBody = selectedDate?.type === MONTH ? requestBodyMonthYear : requestBodyYear;
    // fetching API
    let { useFetch } = useRequestProcessor();
    let { isLoading, isError } = useFetch(
        getKey([MODULE_KEYS.DASHBOARD_GRAPH, selectedDate?.date]),
        async ({ signal }) => await GetGraphData(requestBody, signal),
        {
            onSuccess: (res) => {
                if (res && Object.keys(res)?.length) {
                    modifiedFetchedData(res)
                }
            }
        }
    );

    const modifiedFetchedData = (data) => {
        let modifiedData = [];
        let allUsersInNumberFormat = data?.AllUsers?.Counts.map(el => Number(el));
        let allActiveUsersInNumberFormat = data?.ActiveUsers?.Counts.map(el => Number(el));
        modifiedData.push({ name: TOTAL_USERS, data: allUsersInNumberFormat });
        modifiedData.push({ name: ACTIVE_USERS, data: allActiveUsersInNumberFormat });
        setGraphData([...modifiedData]);
    }

    // func to get selected date from date filter component
    const getFilterData = (dataObj, timePeriod) => {
        setSelectedDate({ date: dataObj, type: timePeriod });
    };

    if(isLoading || isError){
        return(
            <Row>
                <Col>
                    <LoaderFallbackUI
                        isLoader={isLoading}
                        className="roundsection-loader graph-loader"
                    />
                </Col>
            </Row>
        )
    }
    return (
        <Row>
            <Graph getFilterData={getFilterData} data={graphData} />
        </Row>
    );
};

export default GraphSection;

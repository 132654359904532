import React, { useState, useEffect } from "react";

import { CustomButton } from "../../CustomButton";
import AutoComplete from "../../AutoComplete/AutoComplete";
import { customStyles } from "./customStyles";
import {
    monthYear,
    generateYearOptions,
    currentYear,
    generateMonthOptions
} from "../../../utils/dateUtils";

const DATE_FORMAT = "MMMM";
const DEFAULT_DATE_FORMAT = "MMMM, YYYY";
const START_YEAR = 2023;
const MONTH = "month";
const YEAR = "year";

const currentMonthYear = monthYear(DEFAULT_DATE_FORMAT);
const yearOptions = generateYearOptions(START_YEAR);
const monthOptions = generateMonthOptions(DATE_FORMAT);

const GraphFilter = ({isFilterable = false, getFilterData=() => {}}) => {
    const [activeButton, setActiveButton] = useState(MONTH);
    const [selectedValue, setSelectedValue] = useState(currentMonthYear);

    const handleClick = (buttonId) => {
        setActiveButton(buttonId);
        switch (buttonId) {
            case MONTH:
                setSelectedValue(currentMonthYear);
                break;
            case YEAR:
                setSelectedValue(currentYear.toString());
                break;
            default:
        }
    };

    useEffect(() => {
        getFilterData(selectedValue, activeButton)
    }, [selectedValue])
    
    return (
        <div className="clearfix filters-btn">
            {isFilterable && (
                <div className="float-end d-flex filter-btn-con">
                    <CustomButton
                        title="Month"
                        className={`chart-button month-btn shadow-none me-2 ${
                            activeButton === MONTH ? "active" : ""
                        }`}
                        color="#704DE7"
                        outline
                        onClick={() => handleClick(MONTH)}
                    />
                    <CustomButton
                        className={`chart-button year-btn shadow-none me-2 ${
                            activeButton === YEAR ? "active" : ""
                        }`}
                        color="#704DE7"
                        outline
                        onClick={() => handleClick(YEAR)}
                        title="Year"
                    />
                    <AutoComplete
                        customStyles={customStyles.datePicker}
                        options={activeButton == MONTH ? monthOptions : yearOptions}
                        className="date-picker"
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: "#2795ae"
                            }
                        })}
                        maxMenuHeight={200}
                        value={{
                            label: selectedValue,
                            value: selectedValue
                        }}
                        onChange={(selectedOption) => setSelectedValue(selectedOption.label)}
                        placeholder={`Select ${activeButton === MONTH ? "Month" : "Year"}`}
                    />
                </div>
            )}
        </div>
    );
};

export default GraphFilter;

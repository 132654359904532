import React, { useState } from "react";
import crossIcon from "../../assets/images/img-preview-cross.png";
import editIcon from "../../assets/images/app/common/image-edit.svg";
import { imageUploadHandler } from "../../utils/mediaUtils";
import { ImageTypes } from "../../constants";
import "./Style.scss";

const ImageBox = ({
    containerClasses = "",
    crossClasses = "",
    editClasses = "",
    imageClasses = "",
    placeholderImg = "",
    isRemoveable = false,
    isEditable = true,
    imgUrl = "",
    onImageUpload = () => {},
    handleRemoveImage = () => {}
}) => {
    const [imageError, setImageError] = useState(undefined);
    const [imagePreview, setImagePreview] = useState(undefined);

    const initImgUpload = (e) => {
        let file = e.target.files[0];
        if (file) {
            imageUploadHandler({file, 
                imagePreviewCB: setImagePreview, 
                imageErrorCB: setImageError, 
                uploadImgCB: onImageUpload, 
                fileTypes: ImageTypes});
        }
    };

    return (
        <div className={`preview-container ${containerClasses} `}>
            {/* Cross Button  */}
            {isRemoveable && (
                <img
                    role="button"
                    className={`cross-button ${crossClasses}`}
                    src={crossIcon}
                    onClick={handleRemoveImage}
                    alt="cancel icon"
                />
            )}

            {isEditable ? (
                <>
                    <input
                        hidden
                        type="file"
                        accept=".jpg,.jpeg,.png,.pdf"
                        name="file"
                        id="img-input-id"
                        onChange={initImgUpload}
                        disabled={!isEditable}
                    />
                    <label
                        htmlFor="img-input-id"
                        className="place-order-image-con d-flex justify-content-center align-items-center case-detail-accordian-body-img size-modifier"
                    >
                        <img
                            className={`edit-button ${editClasses} ${
                                isEditable ? "cursur--pointer" : ""
                            }`}
                            src={editIcon}
                            alt="edit icon"
                        />
                    </label>
                </>
            ) : null}
            <img
                src={imagePreview || imgUrl || placeholderImg}
                className={`preview-image ${imageClasses}`}
                alt="file to upload"
            />
            {imageError && (
                <span className="d-block text-danger file-upload-error mt-1">{imageError}</span>
            )}
        </div>
    );
};

export default ImageBox;

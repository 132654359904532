import React, { useState } from "react";
import { Col, Row } from "reactstrap";
// import DatePicker from "react-datepicker";
import { CustomModal } from "../../components/CustomModal";
import { TextInput } from "../../components/Input";
import { LABLES, NAMES, PLACEHOLDERS } from "./CourseFieldsName";
import { CustomButton } from "../../components/CustomButton";
import GoogleAutocomplete from "../../components/GoogleAutocomplete/GoogleAutocomplete";
import { MAP_OPTIONS } from "../../constants/MapOptions";
// import "react-datepicker/dist/react-datepicker.css";
import FormikErrorText from "../../components/FormikErrorText/FormikErrorText";
import { useEffect } from "react";
import { useRequestProcessor } from "../../hooks/useRequestProcessors";
import { MODULE_KEYS, getKey } from "../../api/api.keys";
import { useFormik } from "formik";
import { initialValues, validationSchema } from "./FormConfig";
import { ERROR, SUCCESS, showToast } from "../../utils/toastUtils";
import { AddingCourse } from "../../api/api.service";

let {
    COURSE_NAME,
    ADDRESS,
    CITY,
    STATE,
    COURSE_FEE,
    PARKING,
    BASKETS,
    TEE_PADS,
    NOTES,
    OPERATION_TIMINGS,
    COURSE_PROFILE_LINK
} = LABLES;

const TOAST_SUCCESS = "Course Created successfully";
const DOLLAR_IN_PAYLOAD = "$";
const LAYOUT_IN_PAYLOAD = "N/A";
let MAP_ADDRESS_ENTITIES = {
    city: "locality",
    state: "administrative_area_level_1"
};

const AddCourse = ({ open = false, onCancel = () => {}, onCreate = () => {} }) => {
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const [fieldState, setFieldState] = useState(false);

    // reinitialing formik
    useEffect(() => {
        return () => {
            formik.resetForm();
        };
    }, [open]);

    let { useMutate } = useRequestProcessor();
    let { mutate } = useMutate(
        MODULE_KEYS.COURSE_MANAGEMENT_LISTING,
        getKey([MODULE_KEYS.ADD_COURSE]),
        (data) => AddingCourse(data)
    );

    // handle fields validation using formik
    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            addCourseSubmitHandler(values);
        }
    });

    const handleLocationBlur = (e) => {};

    // Form Data
    const addCourseSubmitHandler = async (values) => {
        setIsFormSubmitting(true);
        const {
            address,
            baskets,
            city,
            state,
            courseFee,
            courseName,
            notes,
            operationTimings,
            parking,
            teePads
        } = values;

        let fee = Number(courseFee);
        // let timings = operationTimings.split("-");

        const bodyData = {
            Name: courseName,
            City: city,
            State: state,
            Address: address,
            AddressLatitude: latitude,
            AddressLongitude: longitude,
            Fee: fee,
            Currency: DOLLAR_IN_PAYLOAD,
            Parking: parking,
            Baskets: baskets,
            TeePads: teePads,
            Layouts: LAYOUT_IN_PAYLOAD,
            Notes: notes,
            PdgaProfile: values[NAMES[COURSE_PROFILE_LINK]]
            // Timings: timings
        };

        mutate(bodyData, {
            onSuccess: (res) => createCourseSuccessHandler(),
            onError: (err) => createCourseErrorHandler()
        });
    };

    // handle api success response
    const createCourseSuccessHandler = () => {
        setIsFormSubmitting(false);
        showToast(SUCCESS, TOAST_SUCCESS);
        onCreate();
    };
    // handle api failure response
    const createCourseErrorHandler = (error) => {
        setIsFormSubmitting(false);
        // showToast(ERROR, error?.Message)
    };

    // google autocomplete select handler
    const handlePlaceSelect = (place) => {
        const { lat, lng } = place?.geometry.location;
        setLatitude(lat());
        setLongitude(lng());
        if (place?.address_components.length) {
            const address_cmp = place.address_components;

            let city = "";
            let state = "";
            address_cmp.forEach((component) => {
                const { types, long_name, short_name } = component;
                if (types.includes(MAP_ADDRESS_ENTITIES.city)) {
                    city = long_name || short_name || "";
                }
                if (types.includes(MAP_ADDRESS_ENTITIES.state)) {
                    state = short_name || long_name || "";
                }
            });
            setFieldState(city);
            formik.setFieldValue(NAMES[ADDRESS], place?.formatted_address);
            formik.setFieldValue(NAMES[CITY], city);
            formik.setFieldValue(NAMES[STATE], state);
        }
    };

    return (
        <div>
            <CustomModal isOpen={open} contentClassName="p-4 pt-0" size="lg">
                <form onSubmit={formik.handleSubmit}>
                    <Row className=" my-4">
                        <Col sm="12" md="6" lg="6" xl="6">
                            <TextInput
                                inputClasses="course-input"
                                label={COURSE_NAME}
                                name={NAMES[COURSE_NAME]}
                                placeholder={PLACEHOLDERS[COURSE_NAME]}
                                value={formik.values[NAMES[COURSE_NAME]]}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <FormikErrorText
                                formikInstance={formik}
                                fieldName={NAMES[COURSE_NAME]}
                            />
                        </Col>
                        <Col sm="12" md="6" lg="6" xl="6">
                            <GoogleAutocomplete
                                label={ADDRESS}
                                inputStyles="course-input"
                                fieldName={NAMES[ADDRESS]}
                                name={NAMES[ADDRESS]}
                                placeholder={PLACEHOLDERS[ADDRESS]}
                                onLocationSelect={handlePlaceSelect}
                                handleOnBlur={(e) => handleLocationBlur(e)}
                                value={formik.values[NAMES[ADDRESS]]}
                                onChange={formik.handleChange}
                                componentRestrictions={MAP_OPTIONS}
                            />
                            <FormikErrorText formikInstance={formik} fieldName={NAMES[ADDRESS]} />
                        </Col>
                    </Row>
                    <Row className=" my-4">
                        <Col sm="12" md="6" lg="6" xl="6">
                            <TextInput
                                inputClasses="course-input"
                                label={CITY}
                                name={NAMES[CITY]}
                                placeholder={PLACEHOLDERS[CITY]}
                                value={formik.values[NAMES[CITY]]}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                disabled={fieldState}
                            />
                            <FormikErrorText formikInstance={formik} fieldName={NAMES[CITY]} />
                        </Col>
                        <Col sm="12" md="6" lg="6" xl="6">
                            <TextInput
                                inputClasses="course-input"
                                label={COURSE_FEE}
                                name={NAMES[COURSE_FEE]}
                                placeholder={PLACEHOLDERS[COURSE_FEE]}
                                value={formik.values[NAMES[COURSE_FEE]]}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <FormikErrorText
                                formikInstance={formik}
                                fieldName={NAMES[COURSE_FEE]}
                            />
                        </Col>
                    </Row>
                    <Row className=" my-4">
                        <Col sm="12" md="6" lg="6" xl="6">
                            <TextInput
                                inputClasses="course-input"
                                label={PARKING}
                                name={NAMES[PARKING]}
                                placeholder={PLACEHOLDERS[PARKING]}
                                value={formik.values[NAMES[PARKING]]}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <FormikErrorText formikInstance={formik} fieldName={NAMES[PARKING]} />
                        </Col>
                        {/* <Col sm="12" md="6" lg="6" xl="6">
                            <TextInput
                                inputClasses="course-input"
                                label={OPERATION_TIMINGS}
                                name={NAMES[OPERATION_TIMINGS]}
                                placeholder={PLACEHOLDERS[OPERATION_TIMINGS]}
                                value={formik.values.operationTimings}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <DatePicker
                                selected={formik.values.operationTimings || null}
                                onChange={date => formik.setFieldValue(NAMES[OPERATION_TIMINGS], date)}
                                onBlur={formik.handleBlur}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                                name={NAMES[OPERATION_TIMINGS]}
                            />
                            {formik.touched.operationTimings && formik.errors.operationTimings ? (
                                <span className="error fs-10">
                                    {formik.errors.operationTimings}
                                </span>
                            ) : null}
                        </Col> */}
                        {/* </Row>
                    <Row className=" my-4"> */}
                        <Col sm="12" md="6" lg="6" xl="6">
                            <TextInput
                                inputClasses="course-input"
                                label={BASKETS}
                                name={NAMES[BASKETS]}
                                placeholder={PLACEHOLDERS[BASKETS]}
                                value={formik.values[NAMES[BASKETS]]}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <FormikErrorText formikInstance={formik} fieldName={NAMES[BASKETS]} />
                        </Col>
                    </Row>
                    <Row className=" my-4">
                        <Col sm="12" md="6" lg="6" xl="6">
                            <TextInput
                                inputClasses="course-input"
                                label={TEE_PADS}
                                name={NAMES[TEE_PADS]}
                                placeholder={PLACEHOLDERS[TEE_PADS]}
                                value={formik.values[NAMES[TEE_PADS]]}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <FormikErrorText formikInstance={formik} fieldName={NAMES[TEE_PADS]} />
                        </Col>
                        <Col sm="12" md="6" lg="6" xl="6">
                            <TextInput
                                inputClasses="course-input"
                                label={COURSE_PROFILE_LINK}
                                name={NAMES[COURSE_PROFILE_LINK]}
                                placeholder={PLACEHOLDERS[COURSE_PROFILE_LINK]}
                                value={formik.values[NAMES[COURSE_PROFILE_LINK]]}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <FormikErrorText
                                formikInstance={formik}
                                fieldName={NAMES[COURSE_PROFILE_LINK]}
                            />
                        </Col>
                    </Row>
                    <Row className=" my-4">
                        <Col sm="12" md="6" lg="6" xl="6">
                            <TextInput
                                inputClasses="course-input"
                                label={NOTES}
                                name={NAMES[NOTES]}
                                placeholder={PLACEHOLDERS[NOTES]}
                                value={formik.values[NAMES[NOTES]]}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            <FormikErrorText formikInstance={formik} fieldName={NAMES[NOTES]} />
                        </Col>
                    </Row>

                    <div className="course-modal-resopnsiveness d-flex justify-content-lg-start justify-content-md-start justify-content-sm-center flex-wrap mt-5 mb-4">
                        <CustomButton
                            title="Create"
                            color="primary"
                            className="custom-btn-lg"
                            type="submit"
                            loading={isFormSubmitting}
                        />
                        <CustomButton
                            type="button"
                            title="Cancel"
                            outline
                            color="primary"
                            className="custom-btn-lg-secondary mx-md-2 mx-lg-2"
                            onClick={onCancel}
                            disabled={isFormSubmitting}
                        />
                    </div>
                </form>
            </CustomModal>
        </div>
    );
};

export default AddCourse;

export const MODULE_KEYS = {
    USER_MANAGEMENT_LISTING: "USER_MANAGEMENT_LISTING",
    CONTENT_MANAGEMENT_LISTING: "CONTENT_MANAGEMENT_LISTING",
    CONTENT_MANAGEMENT_CREATE: "CONTENT_MANAGEMENT_CREATE",
    COURSE_MANAGEMENT_LISTING: "COURSE_MANAGEMENT_LISTING",
    ADD_COURSE: "ADD_COURSE",
    DETAIL_COURSE: "DETAIL_COURSE",
    COURSE_STATUS: "COURSE_STATUS",
    UPDATE_COURSE: "UPDATE_COURSE",
    COURSE_MANAGEMENT_ROUNDS_LISTING: "COURSE_MANAGEMENT_ROUNDS_LISTING",
    AD_MANAGEMENT_LISTING: "AD_MANAGEMENT_LISTING",
    ACCOUNT_CHANGE_PASSWORD: "ACCOUNT_CHANGE_PASSWORD",
    PROFILE_UPDATED: "PROFILE_UPDATE",
    USER_DETAILS_PROFILE: "USER_DETAILS_PROFILE",
    USER_DETAILS_ROUNDS: "USER_DETAILS_ROUNDS",
    USER_DETAILS_CONNECTIONS: "USER_DETAILS_CONNECTIONS",
    DASHBOARD_ROUNDS: "DASHBOARD_ROUNDS",
    DASHBOARD_ANALYTICS: "DASHBOARD_ANALYTICS",
    DASHBOARD_GRAPH: "DASHBOARD_GRAPH",
    CONTENT_DETAILS: "CONTENT_DETAILS",
    UPDATE_USER: "UPDATE_USER",
    CREATE_AD: "CREATE_AD",
    UPDATE_AD: "UPDATE_AD",    
    UPDATE_CONTENT: "UPDATE_CONTENT",
    DELETE_CONTENT: "DELETE_CONTENT",
};

export const getKey = (keys = [], params = {}) => {
    const KEYS = [...keys];

    const hasParams = Object.keys(params);

    if (hasParams.length) {
        KEYS.push(params);
    }

    return KEYS;
};
